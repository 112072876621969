import navbar from "@src/redux/navbar";
import layout from "@src/redux/layout";
import auth from "@src/redux/authentication";
import app from "@src/redux/app";
import users from "@src/views/user/store";
import {lazy} from "react";
import {
	Activity, Archive,
	Book,
	Briefcase,
	Calendar, Cast,
	Circle,
	Clock,
	Command,
	File,
	Grid,
	Home,
	Mail,
	Settings,
	User,
	Flag,
	Tool,
	Users,
	PieChart,
	TrendingUp,
	Folder,
    HelpCircle,
    Printer,
    AtSign
} from 'react-feather'


const backendBasePath = process.env.REACT_APP_HOST_API

// This file holds the common configuration for the application:
// 1. Auth configuraiton : JWT olympus authentication
// 2. API endpoints
// 3. Olympus reducers
// 4. Routing System endpoints

export default {
    auth: {
        basePath: `${backendBasePath}auth`,
        loginEndpoint: `${backendBasePath}auth/initiate_auth`,
        registerEndpoint: `${backendBasePath}auth/account/registration`,
        refreshEndpoint: `${backendBasePath}auth/refresh_auth`,
        logoutEndpoint: `${backendBasePath}auth/logout`,
        tokenType: "Bearer",
        storageTokenKeyName: "accessToken",
        storageRefreshTokenKeyName: "refreshToken",
        storageIdTokenKeyName: "idToken",
        sendVerificationEmailEndpoint: `${backendBasePath}auth/account/email-verification/send-email`,
        verifyEmailEndpoint: `${backendBasePath}auth/account/email-verification`,
        activationAccountEndpoint: `${backendBasePath}auth/account/activation/send-email`,
        forgotPasswordEndpoint: `${backendBasePath}auth/password/forgot`,
        challengeEndpoint: `${backendBasePath}auth/respond_to_challenge`,
        changePasswordEndpoint: `${backendBasePath}auth/password/reset`,
        changeTempPasswordEndpoint: `${backendBasePath}auth/password/change`
    },
    API: {
        assets: `${backendBasePath}assets`,
        usersAPI: `${backendBasePath}users`,
        plants: `${backendBasePath}assets/plants`,
        processes: `${backendBasePath}assets/processes`,
        countries: `${backendBasePath}not-used`,
        contracts: `${backendBasePath}assets/contracts`,
        contractTypes: `${backendBasePath}assets/contract_types`,
        customers: `${backendBasePath}assets/customers`,
        groups: `${backendBasePath}assets/plant_groups`,
        groups_supervisor: `${backendBasePath}assets/plant_groups_supervisor`,
        inspection_reports: `${backendBasePath}assets/inspection_reports`,
        mainteiners: `${backendBasePath}assets/technicians`,
        submit_reports: `${backendBasePath}planning/maintenances`,
        companies: `${backendBasePath}assets/companies`,
        dashboard231: `${backendBasePath}model231/dashboard`,
        company231: `${backendBasePath}model231/companies`,
        checklist231: `${backendBasePath}model231/checklists`,
        public231: `${backendBasePath}model231/public/invitation`,
        planners: `${backendBasePath}assets/planners`,
        reports: `${backendBasePath}assets/report_types`,
        activities: `${backendBasePath}assets/activities`,
        maintenances: `${backendBasePath}planning/maintenances`,
        maintenanceType:  `${backendBasePath}assets/maintenance_types`,
        workorders: `${backendBasePath}assets/workorders`,
        planning: `${backendBasePath}planning`,
        availability: `${backendBasePath}availability`,
        customer: `${backendBasePath}public/maintenances`,
        hse_docs_categories: `${backendBasePath}hse_docs_categories`,
        hse_docs: `${backendBasePath}hse_docs`,
        kaleyra:  `${backendBasePath}kaleyra`,
        comunication_groups: `${backendBasePath}communication_recipient_groups`,
        comunications: `${backendBasePath}communications`,
        comunication_types: `${backendBasePath}communication_types`,
        dashboard: `${backendBasePath}dashboard`,
        KPI: `${backendBasePath}kpis`,
        mobile_app: `${backendBasePath}mobile_app`,
        aiPath: `${backendBasePath}ai`,
        proposals: `${backendBasePath}proposals`
    },
    reduxReducers: {
        auth,
        app,
        users,
        navbar,
        layout,
    },
    routing: {
        routes: [
            {
                path: "/ai",
                component: lazy(() => import("../hermes/ai")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "validator-231": false,
                    "manager-231": false,
                    "manager": false,
                    "commercial": false,
                    "marketing": false
                }
            },
            {
                path: "/checklist_validation/:id",
                component: lazy(() => import("../hermes/231/checklist/index")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: false,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "validator-231": true,
                    "manager-231": false,
                    "manager": false,
                    "commercial": false,
                    "marketing": false
                }
            },
            {
                path: "/reports",
                component: lazy(() => import("../hermes/report/report")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": true,
                    "manager": true,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                }
            },
            {
                path: "/contracts",
                component: lazy(() => import("../hermes/anagrafica/contracts/list")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                }
            },
            {
                path: "/customers",
                component: lazy(() => import("../hermes/anagrafica/customers/list")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                }
            },

            {
                path: "/kpi_management",
                component: lazy(() => import("../hermes/kpi_management/kpi_phases")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": true,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                }
            },
            {
                path: "/dashboard",
                component: lazy(() => import("../hermes/dashboard")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: true,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": true,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/agenda/cancelled_maintenances/:agenda_date/:agenda_id",
                component: lazy(() => import("../hermes/agenda/cancelledMaintenanceView")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: true,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": true,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": true,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/agenda",
                component: lazy(() => import("../hermes/agenda")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: true,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": true,
                    logistician: false,
                    "activity-supervisor": true,
                    "manager": true,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
             {
                path: "/activities/view/:id",
                component: lazy(() => import("../hermes/activities/view")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: true,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": true,
                    logistician: true,
                    "activity-supervisor": true,
                    "manager": true,
                    "validator-231": false,
                     "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/activities",
                component: lazy(() => import("../hermes/activities/list")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: true,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": false,
                    logistician: true,
                    "activity-supervisor": true,
                    "manager": true,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/processes",
                component: lazy(() => import("../hermes/anagrafica/processes/list")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/maintenances/:id/plants_view/:plantId",
                component: lazy(() => import("../hermes/maintenance/plant_view")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: true,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": true,
                    logistician: false,
                    "activity-supervisor": true,
                    "manager": true,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/maintenances",
                component: lazy(() => import("../hermes/maintenance/list")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: true,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": true,
                    logistician: false,
                    "activity-supervisor": true,
                    "manager": true,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/comunications/view/:id",
                component: lazy(() => import("../hermes/comunication/view")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: true,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": true,
                    logistician: true,
                    "activity-supervisor": true,
                    "manager": true,
                    "validator-231": true,
                    "manager-231": true,
                    "commercial": true,
                    "marketing": true
                },
            },
            {
                path: "/comunications/new",
                component: lazy(() => import("../hermes/comunication/new")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": true,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
             {
                path: "/comunication_groups/new",
                component: lazy(() => import("../hermes/comunication/groups/new")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
             },
            {
                path: "/comunication_groups/view/:id",
                component: lazy(() => import("../hermes/comunication/groups/edit")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": true,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/comunication_groups",
                component: lazy(() => import("../hermes/comunication/groups/index")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": true,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/comunications",
                component: lazy(() => import("../hermes/comunication/index")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: true,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": true,
                    logistician: true,
                    "activity-supervisor": true,
                    "manager": true,
                    "validator-231": true,
                    "manager-231": true,
                    "commercial": true,
                    "marketing": true
                },
            },
            {
                path: "/comunication_types/view/:id",
                component: lazy(() => import("../hermes/comunication/types/view")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": true,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/comunication_types/new",
                component: lazy(() => import("../hermes/comunication/types/new")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/comunication_types",
                component: lazy(() => import("../hermes/comunication/types/index")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": true,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/workorders",
                component: lazy(() => import("../hermes/anagrafica/workorders/list")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/attendance",
                component: lazy(() => import("../hermes/attendance")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: true,
                    administrator: false,
                    "backoffice-manager": true,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/downloadapp",
                component: lazy(() => import("../hermes/app")),
                layout: "BlankLayout",
                meta: {
                    publicRoute: false,
                    technician: true,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/home",
                component: lazy(() => import("../hermes/home")),
                meta: {
                    publicRoute: false,
                    technician: true,
                    planner: true,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": true,
                    logistician: true,
                    "activity-supervisor": true,
                    "manager": true,
                    "validator-231": true,
                    "manager-231": true,
                    "commercial": true,
                    "marketing": true
                },
            },
            {
                path: "/company_configuration",
                component: lazy(() => import("../hermes/anagrafica/companies/configuration/index")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/plants/view/:id",
                component: lazy(() => import("../hermes/anagrafica/plants/view")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: true,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": true,
                    logistician: true,
                    "activity-supervisor": true,
                    "manager": true,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/plants",
                component: lazy(() => import("../hermes/anagrafica/plants/list")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: true,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": true,
                    logistician: true,
                    "activity-supervisor": true,
                    "manager": true,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
             {
                path: "/groups/view/:id",
                component: lazy(() => import("../hermes/anagrafica/groups/view")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/groups/add",
                component: lazy(() => import("../hermes/anagrafica/groups/add")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/groups",
                component: lazy(() => import("../hermes/anagrafica/groups/list")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
             {
                path: "/supervisor_groups/view/:id",
                component: lazy(() => import("../hermes/anagrafica/supervisor_groups/view")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                     "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/supervisor_groups/add",
                component: lazy(() => import("../hermes/anagrafica/supervisor_groups/add")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/supervisor_groups",
                component: lazy(() => import("../hermes/anagrafica/supervisor_groups/list")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
             {
                path: "/companies231/view/:id",
                component: lazy(() => import("../hermes/231/companies/view")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: false,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": true,
                    "manager-231": true,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/companies231/new",
                component: lazy(() => import("../hermes/231/companies/new")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: false,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": true,
                    "commercial": false,
                    "marketing": false
                },
            },
             {
                path: "/companies231",
                component: lazy(() => import("../hermes/231/companies/list")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: false,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": true,
                    "manager-231": true,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/companies/view/:id",
                component: lazy(() => import("../hermes/anagrafica/companies/view")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": true,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/companies",
                component: lazy(() => import("../hermes/anagrafica/companies/list")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": true,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            // {
            //     path: "/companies/add",
            //     component: lazy(() => import("../hermes/anagrafica/companies/add")),
            //     meta: {
            //         publicRoute: false,
            //         technician: false,
            //         planner: false,
            //         administrator: true,
            //         "backoffice-manager": false,
            //         "hse-manager": false,
            //         logistician: false
            //     },
            // },
            {
                path: "/hse_doc",
                component: lazy(() => import("../hermes/anagrafica/hse_docs/list")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": true,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/company_hse_docs",
                component: lazy(() => import("../hermes/anagrafica/companies/company_hse_docs")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": true,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/plant_hse_docs",
                component: lazy(() => import("../hermes/anagrafica/plants/plant_hse_docs")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": true,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false
                },
            },
			{
				path: "/maintenance_hse_docs",
				component: lazy(() => import("../hermes/maintenance/hse_docs/maintenance_hse_docs")),
				meta: {
					publicRoute: false,
					technician: false,
					planner: false,
					administrator: true,
					"backoffice-manager": false,
					"hse-manager": true,
					logistician: false,
					"activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
				},
			},
            {
                path: "/user_hse_docs",
                component: lazy(() => import("../hermes/anagrafica/users/user_hse_docs")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": true,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                path: "/proposals/view/:id",
                component: lazy(() => import("../hermes/anagrafica/proposals/view")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: false,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": true,
                    "marketing": true
                },
            },
            {
                path: "/proposals",
                component: lazy(() => import("../hermes/anagrafica/proposals/list")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: false,
                    administrator: false,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": true,
                    "marketing": true
                },
            },
            {
                path: "/login",
                component: lazy(() => import("@src/views/Login")),
                layout: "BlankLayout",
                meta: {
                    authRoute: true,
                },
            },
            {
                path: "/logout",
                component: lazy(() => import("@src/views/Logout")),
                layout: "BlankLayout",
                meta: {
                    authRoute: true,
                },
            },
            {
                path: "/error",
                component: lazy(() => import("@src/views/Error")),
                layout: "BlankLayout",
                meta: {
                    publicRoute: true,
                },
            },
            {
                path: "/must-change-password",
                component: lazy(() => import("olympus-frontend/src/views/MustChangePassword")),
                layout: "BlankLayout",
                meta:{
                    publicRoute:true
                }
            },
            // ** Only internal view, must have meta **/
            {

                path: "/change-password",
                component: lazy(() => import("olympus-frontend/src/views/ChangePassword")),
                layout: "BlankLayout",
                meta: {
                    publicRoute: false,
                    planner: true,
                    technician: true,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": true,
                    logistician: true,
                    "activity-supervisor": true,
                    "manager": true,
                    "validator-231": true,
                    "manager-231": true,
                    "commercial": true,
                    "marketing": true
                }
            },
            {
                path: "/forgot-password",
                component: lazy(() => import("@src/views/ForgotPassword")),
                layout: "BlankLayout",
                meta: {
                    authRoute: true,
                },
            },
            {
                path: "/reset-password/:token",
                component: lazy(() => import("@src/views/ResetPasswordBasic")),
                layout: "BlankLayout",
                meta: {
                    authRoute: true,
                },
            },
            {
                path: "/send-email-verification",
                component: lazy(() => import("@src/views/VerifyEmailBasic")),
                layout: "BlankLayout",
                meta: {
                    authRoute: true,
                },
            },
            {
                path: "/verify-email/:token",
                component: lazy(() => import("@src/views/VerificationEmailLanding")),
                layout: "BlankLayout",
                meta: {
                    authRoute: true,
                },
            },
            {
                path: "/polling-activation",
                component: lazy(() => import("@src/views/PollingActivation")),
                layout: "BlankLayout",
                meta: {
                    authRoute: true,
                },
            },
            {
                path: "/users",
                component: lazy(() => import("../hermes/anagrafica/users/list")),
                meta: {
                    publicRoute: false,
                    planner: false,
                    technician: false,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": true,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                }
            },
            {
                path: "/appointment_verification/:code",
                component: lazy(() => import("../hermes/appointment/verifyPage")),
                layout: "BlankLayout",
                meta: {
                    publicRoute: true
                },
            },
            {
                path: "/checklist_verification/:id",
                component: lazy(() => import("../hermes/231/public/verifyPage")),
                layout: "BlankLayout",
                meta: {
                    publicRoute: true
                },
            },
            {
                path: "/checklist/:id",
                component: lazy(() => import("../hermes/231/public/index")),
                layout: "BlankLayout",
                meta: {
                    publicRoute: true
                }
            },
            {
                path: "/app/checkin/:code",
                component: lazy(() => import("../hermes/appointment/checkin")),
                layout: "BlankLayout",
                meta: {
                    publicRoute: true
                }
            },
            {
                path: "/app/:code",
                component: lazy(() => import("../hermes/appointment")),
                layout: "BlankLayout",
                meta: {
                    publicRoute: true,
                    planner: true,
                    technician: false,
                    administrator: false,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                }
            },
            {
                path: "/support",
                component: lazy(() => import("../hermes/supporto")),
                meta: {
                    publicRoute: false,
                    technician: false,
                    planner: true,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": true,
                    logistician: true,
                    "activity-supervisor": true,
                    "manager": true,
                    "validator-231": true,
                    "manager-231": true,
                    "commercial": false,
                    "marketing": false
                },
            },
        ],
        defaultRoute: "/home",
    },
    layout: {
        isRTL: false,
        skin: "light", // light, dark, bordered, semi-dark
        routerTransition: "fadeIn", // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
        type: "vertical", // vertical, horizontal
        contentWidth: "full", // full, boxed
        menu: {
            isHidden: false,
            isCollapsed: true,
        },
        navbar: {
            // ? For horizontal menu, navbar type will work for navMenu type
            type: "static", // static , sticky , floating, hidden
            backgroundColor: "", // BS color options [primary, success, etc]
        },
        footer: {
            type: "static", // static, sticky, hidden
        },
        customizer: false,
        navbarNavigation: [
            {
                header: 'Main',
                meta: {
                    technician: false,
                    planner: true,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": true,
                    logistician: true,
                    "activity-supervisor": true,
                    "manager": true,
                    "validator-231": true,
                    "manager-231": true,
                    "commercial": true,
                    "marketing": true
                },
            },
            {
                id: "home",
                title: "Home",
                icon: <Home size={20}/>,
                navLink: "/home",
                meta: {
                    //TODO : BUTTON IS NOT VISIBLE
                    //TODO : manage the home as a page!
                    technician: false,
                    planner: false,
                    administrator: false,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                }
            },
            {
                id: "dashboard",
                title: "Dashboard",
                icon: <Home size={20}/>,
                navLink: "/dashboard",
                meta: {
                    technician: false,
                    planner: true,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": true,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                }
            },
            {
                id: "company231",
                title: "Companies231",
                icon: <Home size={20}/>,
                navLink: '/companies231',
                meta: {
                    technician: false,
                    planner: false,
                    administrator: false,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "validator-231": true,
                    "manager-231": true,
                    "manager": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                id: "performance",
                title: "Performance",
                icon: <TrendingUp size={20}/>,
                navLink: "/kpi_management",
                meta: {
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": true,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
                children: [
                    {
                        id: "kpi_management",
                        title: "Process",
                        icon: <Circle size={12}/>,
                        navLink: "/kpi_management",
                        meta: {
                            technician: false,
                            planner: false,
                            administrator: true,
                            "backoffice-manager": false,
                            "hse-manager": false,
                            logistician: false,
                            "activity-supervisor": true,
                            "manager": false,
                            "validator-231": false,
                            "manager-231": false,
                            "commercial": false,
                            "marketing": false
                        }
                    }
                ],
            },
            {
                id: "agenda",
                title: "Agenda",
                icon: <Calendar size={20}/>,
                navLink: "/agenda",
                meta: {
                    technician: false,
                    planner: true,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": true,
                    logistician: false,
                    "activity-supervisor": true,
                    "manager": true,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                }
            },
            {
                id: "activity-list",
                title: "Activity List",
                icon: <Activity size={12}/>,
                navLink: "/activities",
                meta: {
                    technician: false,
                    planner: true,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": false,
                    logistician: true,
                    "activity-supervisor": true,
                    "manager": true,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                }
            },
            {
                id: "maintenance-list",
                title: "Maintenances List",
                icon: <Tool size={12}/>,
                navLink: "/maintenances",
                meta: {
                    technician: false,
                    planner: true,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": true,
                    logistician: false,
                    "activity-supervisor": true,
                    "manager": true,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                }
            },
            {
                id: "attendance",
                title: "Attendances",
                icon: <Clock size={20}/>,
                navLink: "/attendance",
                meta: {
                    technician: false,
                    planner: true,
                    administrator: false,
                    "backoffice-manager": true,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                }
            },
            {
                id: "configuration",
                title: "ConfigurationX",
                icon: <Settings size={20}/>,
                navLink: "/company_configuration",
                meta: {
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
                children: [
                    {
                        id: "company_configuration",
                        title: "Company",
                        icon: <Circle size={12}/>,
                        navLink: "/company_configuration",
                        meta: {
                            technician: false,
                            planner: false,
                            administrator: true,
                            "backoffice-manager": true,
                            "hse-manager": false,
                            logistician: false,
                            "activity-supervisor": false,
                            "manager": false,
                            "validator-231": false,
                            "manager-231": false,
                            "commercial": false,
                            "marketing": false
                        }
                    }
                ],
            },
            {
                id: "comunication_list",
                title: "Comunications",
                badge: 'primary',
                badgeText: '',
                comunicationBadge: true,
                icon: <Mail size={20}/>,
                navLink: "/comunications",
                meta: {
                    technician: false,
                    planner: true,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": true,
                    logistician: true,
                    "activity-supervisor": true,
                    "manager": true,
                    "validator-231": true,
                    "manager-231": true,
                    "commercial": true,
                    "marketing": true
                }
            },
            {
                header: 'Registry',
                meta: {
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": true,
                    logistician: false,
                    "activity-supervisor": true,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                id: "plants",
                title: "Plants",
                icon: <Home size={20}/>,
                navLink: '/plants',
                meta: {
					technician: false,
					planner: true,
					administrator: true,
					"backoffice-manager": true,
                    "hse-manager": true,
					logistician: false,
                    "activity-supervisor": true,
                    "manager": true,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },

            {
                id: "customers",
                title: "Customers",
                icon: <AtSign size={20}/>,
                navLink: '/customers',
                meta: {
					technician: false,
					planner: false,
					administrator: true,
					"backoffice-manager": false,
                    "hse-manager": false,
					logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                id: "groups",
                title: "Groups",
                icon: <Grid size={20}/>,
                navLink: '/groups',
                meta: {
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
                children: [
                    {
                        id: "maintenance_groups",
                        title: "Maintenance Groups",
                        icon: <Circle size={12}/>,
                        navLink: "/groups",
                        meta: {
                            technician: false,
                            planner: false,
                            administrator: true,
                            "backoffice-manager": false,
                            "hse-manager": false,
                            logistician: false,
                            "activity-supervisor": false,
                            "manager": false,
                            "validator-231": false,
                            "manager-231": false,
                            "commercial": false,
                            "marketing": false
                        }
                    },
                    {
                        id: "supervisor_groups",
                        title: "Supervisor Groups",
                        icon: <Circle size={12}/>,
                        navLink: "/supervisor_groups",
                        meta: {
                            technician: false,
                            planner: false,
                            administrator: true,
                            "backoffice-manager": false,
                            "hse-manager": false,
                            logistician: false,
                            "activity-supervisor": false,
                            "manager": false,
                            "validator-231": false,
                            "manager-231": false,
                            "commercial": false,
                            "marketing": false
                        }
                    }
                ],
            },

            {
                id: "contracts",
                title: "Contracts",
                icon: <Printer size={20}/>,
                navLink: '/contracts',
                meta: {
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                id: "processes",
                title: "Processes",
                icon: <Command size={20}/>,
                navLink: '/processes',
                meta: {
					technician: false,
					planner: false,
					administrator: true,
					"backoffice-manager": false,
                    "hse-manager": false,
					logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                id: "workorders",
                title: "Workorders",
                icon: <Folder size={20}/>,
                navLink: '/workorders',
                meta: {
					technician: false,
					planner: false,
					administrator: true,
					"backoffice-manager": false,
                    "hse-manager": false,
					logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                id: "company",
                title: "Companies",
                icon: <Briefcase size={20}/>,
                navLink: '/companies',
                meta: {
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": true,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                id: "hse_docs",
                title: "Hse Docs",
                icon: <File size={20}/>,
                navLink: '/hse_doc',
                meta: {
					technician: false,
					planner: false,
					administrator: true,
					"backoffice-manager": false,
                    "hse-manager": true,
					logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                id: "users",
                title: "Users",
                icon: <User size={20}/>,
                navLink: '/users',
                meta: {
                    planner: false,
                    technician: false,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": true,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                id: "comunication_groups",
                title: "Comunication Groups",
                icon: <Users size={12}/>,
                navLink: "/comunication_groups",
                meta: {
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": true,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                }
            },
            {
                id: "comunication_types",
                title: "Comunication Types",
                icon: <Archive size={12}/>,
                navLink: "/comunication_types",
                meta: {
                    technician: false,
                    planner: false,
                    administrator: true,
                    "backoffice-manager": false,
                    "hse-manager": true,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": false,
                    "marketing": false
                }
            },
            {
                id: "proposal",
                title: "Proposals",
                icon: <Archive size={20}/>,
                navLink: '/proposals',
                meta: {
                    technician: false,
                    planner: false,
                    administrator: false,
                    "backoffice-manager": false,
                    "hse-manager": false,
                    logistician: false,
                    "activity-supervisor": false,
                    "manager": false,
                    "validator-231": false,
                    "manager-231": false,
                    "commercial": true,
                    "marketing": true
                },
            },
            {
                header: 'Support',
                meta: {
                    planner: true,
                    technician: false,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": true,
                    logistician: true,
                    "activity-supervisor": true,
                    "manager": true,
                    "validator-231": true,
                    "manager-231": true,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                // Olympus-FE will search for this ID and remove the href and attach the event handler for appInfo.appWikiUrl if available
                // If not, the navLink will be triggered and forwarded to its page.
                id: "userGuideButton",
                title: "Guide",
                icon: <Book size={20}/>,
                navLink:'/guida',
                meta: {
                    planner: true,
                    technician: false,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": true,
                    logistician: true,
                    "activity-supervisor": true,
                    "manager": true,
                    "validator-231": true,
                    "manager-231": true,
                    "commercial": false,
                    "marketing": false
                },
            },
            {
                // Olympus-FE will search for this ID and remove the href and attach the event handler for appInfo.appSupportUrl if available
                // If not, the navLink will be triggered and forwarded to its page.
                id: "userSupportButton",
                title: "Support",
                icon: <HelpCircle size={20}/>,
                navLink:'/support',
                meta: {
                    planner: true,
                    technician: false,
                    administrator: true,
                    "backoffice-manager": true,
                    "hse-manager": true,
                    logistician: true,
                    "activity-supervisor": true,
                    "manager": true,
                    "validator-231": true,
                    "manager-231": true,
                    "commercial": false,
                    "marketing": false
                },
            }
        ],
        scrollTop: true, // Enable scroll to top button
    }
}
